@import url("https://rsms.me/inter/inter.css");

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

div {
  box-sizing: border-box;
}

input,
button {
  outline: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Animations */

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes modalFadeOut {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: translateX(20%);
    opacity: 0;
  }
}

@keyframes modalOverlayFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes modalOverlayFadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
